<template>
  <div class="home">
    <div class="section section-one">
      快適なotintin.shopライフを
    </div>
    <div class="section section-two">
      みんながあこがれるotintin.shopに
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.section {
  height: 320px;
}

.section-one {
  
}
</style>
