<template>
	<header>
		<h1 class="header-title">otintin.shop</h1>
		<div class="header-menu">
			<span class="header-menu-content">カート</span>
		</div>
	</header>
</template>

<script>
export default {
	name: 'OtintinHeader'
}
</script>

<style scoped>

header {
	padding: 10px;
	background-color: #E6DDC4;
	display: flex;
	align-items: center;
}

.header-title {
	margin-left: 5px;
}

.header-menu {
	margin-left: auto;
	margin-right: 5px;
}

.header-menu-content {
	margin: 0 2.5px;
}

</style>