<template>
	<otintin-header></otintin-header>
	<router-view/>
</template>

<script>
import OtintinHeader from './components/OtintinHeader.vue';

export default {
	name: 'App',
	components: {
		OtintinHeader
	}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@500&display=swap');

body {
	background-color: #F7F7F7;
	font-family: 'Zen Maru Gothic', sans-serif;
	margin: 0;
}

h1 {
	margin: 0;
}
</style>
